import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { permissionModel } from '../models/permissiom.model';

import { environment } from '../../environments/environment';

@Injectable()
export class permissionService {

  constructor(public http: HttpClient) {

  }

  
  permissionAll(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/auth/permission', this.getHeaders());
  }

  permissionID(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/auth/permission/' + id, this.getHeaders());
  }

  save(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/auth/permission', data, this.getHeaders());
  }

  update(id: number, data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/auth/permission/' + id, data, this.getHeaders());
  }

  getHeaders(): {headers: HttpHeaders} {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Accept': 'application/json'
    };
    return {headers: new HttpHeaders(headerOptions)};
  }

}
