import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { permissionModel } from '../models/permissiom.model';

import { environment } from '../../environments/environment';

@Injectable()
export class UserService {

  constructor(public http: HttpClient) {

  }

  allUsers(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/auth/all/users', this.getHeaders());
  }

  userID(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/auth/user/' + id, this.getHeaders());
  }

  save(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/auth/signup', data, this.getHeaders());
  }

  update(id: number, data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/auth/users/' + id, data, this.getHeaders());
  }

  getHeaders(): {headers: HttpHeaders} {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Accept': 'application/json'
    };
    return {headers: new HttpHeaders(headerOptions)};
  }

}
